import React, {useState, useEffect} from 'react'
import {getUsersPages} from '../firebase/DbConnection'


const UserPage=(props)=>{
    // const urlParams = new URLSearchParams(window.location.search);
const userName= props.match.params.userName
const userId=props.match.params.id

console.log("UserPage>props:  ",props)
    const [Pages,setPages]=useState([])
 useEffect(()=>{  
    getUsersPages(userName, userId).then(pages=>{
console.log("UserPage>pages:  ",pages)
setPages(pages)
    })},[]) 
return(<div>
    <h1>about me:</h1>
    my name is: {userName}
<h1>The chats I took part in...</h1>
{Pages.map((p)=>{
    return(<div>
        <a href={`../../Documentation/${p}`} >{p}</a>
    </div>)
})}

</div>)
    
}

export default UserPage