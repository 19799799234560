"use strict"

import React, { useState, useEffect } from "react";


import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Back from "../Back/Back";
import {getParticipantsOn, getUserFingerprint, getChatDocumentation} from '../../firebase/DbConnection'

import './Chat.css';


let fingerprin

const Chat = (props) => {
 
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  
  
  useEffect(()=>{getParticipantsOn(room,setUsers)},[room])
  
  useEffect(() => {
    getUserFingerprint().then(v=>{fingerprin=v})
     const room=props.match.params.room
    
    setRoom(room);
     getChatDocumentation(room).then((v)=>{setMessages(v)})

  }, [room]);
  
  
console.log("messages:  ",messages)
 
 

  return (
    <div className="container">
      <div className="content">
        <div className="content_info_bar">
          <InfoBar room={room} name="Doc" users={users} />
        </div>
        <div className="content_back">
          <Back  />
        </div>
        <div className="content_messages">
          <Messages messages={messages} name="Doc" users={users}/>
             </div>
      </div> 
    </div>
  );
}


export default Chat;
