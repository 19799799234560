"use strict"

import React from 'react';

import Chat from './components/Chat/Chat';
import Join from './components/Join/Join';
import JoinByRoom from './components/JoinByRoom/JoinByRoom';
import UserPage from './components/userPage'
import ChatDocumentation from './components/ChatDocumentation/ChatDocumentation';

import { BrowserRouter as Router, Route } from "react-router-dom";

const App = () => {


  return (
    <Router>
      <Route path="/" exact component={Join} />
      <Route path="/chat" component={Chat} />
      <Route path="/users/:id/:userName" component={UserPage} />
      <Route path="/Documentation/:room" component={ChatDocumentation} />
      <Route path="/JoinByRoom/:room" component={JoinByRoom} />
    </Router>
  );
}

export default App;
