// const DB = require('./firebase')
// const moment = require('moment')
import DB from './firebase'
// import moment from 'moment'
import FingerprintJS from '@fingerprintjs/fingerprintjs';



const userJoin=(name,room)=>{
    getUserFingerprint().then((Fingerprint)=>{
        getUserInRoom(room,Fingerprint,name).then((user)=>{
            DB.ref(`room/${room}/users/${Fingerprint}/${name}/activity`).set(true)
            sendMessage(room,name+" came back",Fingerprint,"admin")
        }).catch((r)=>{
            // אם אין  משתמש והגיעו נתונים - זה הודעת השגיאה
            if(r){alert("Problem getting the data, please try again later")}
            else{
                getRoomUsersIndex(room).then((i)=>{
                    DB.ref(`room/${room}/users/${Fingerprint}/${name}`).set({activity:true,index:i})
                    sendMessage(room,name+" Join us",Fingerprint,"admin")
                }).catch((e)=>{alert("Problem getting the data, please try again later")})
            }
        })
        DB.ref(`users/${Fingerprint}/${name}/${room}`).set(new Date().getTime())
        // DB.ref(`room/${room}/users/${Fingerprint}/${name}/activity`).onDisconnect().set(false)
    })
}

const getParticipantsOnce=(room)=>{
    return new Promise ((resolve, reject) => {
    DB.ref(`room/${room}/users`).once('value')
            .then((snapshot) => {
                resolve(snapshot.val())
            }).catch((e)=>{reject(e)})
})}

const getParticipantsOn=(room,callback)=>{
   
    DB.ref(`room/${room}/users`).on('value', (snapshot) => {
        let usersArray=[]
        const usersObject=snapshot.val()
    console.log("DBC>usersObject:  ",usersObject)
    console.log("DBC>roomt:  ",room)

        usersObject&& Object.keys(usersObject).map(uid=>{
            Object.keys(usersObject[uid]).map(username=>{
usersArray.push({...usersObject[uid][username],username,uid})
console.log("DBC>usersArray:  ",usersArray)

            })
        })
    console.log("DBC>usersArray:  ",usersArray)

        callback(usersArray)
     })
           }




const getUserInRoom=(room,Fingerprint,name)=>{
    return new Promise ((resolve, reject) => {
    DB.ref(`room/${room}/users/${Fingerprint}/${name}`).once('value').then((snapshot)=>{
        if(snapshot.val()) resolve(snapshot.val())
        reject(null)
    }).catch((e)=>{reject(e)})

})}

const getRoomUsersIndex=(room)=>{
    return new Promise ((resolve, reject) => {
DB.ref(`room/${room}/users/index`).once('value').then((snapshot)=>{
    DB.ref(`room/${room}/users/index`).set(snapshot.val()+1)
    resolve(snapshot.val()+1)
}).catch((e)=>{reject(e)})
})}

const getUsersPages=(userName,userIp)=>{
    return new Promise ((resolve, reject) => {
   
DB.ref(`users/${userIp}/${userName}`).once('value').then(snapshot=>{
    console.log("getUsersPages>userIp:  ",userIp)
    console.log("getUsersPages>userName:  ",userName)
    console.log("getUsersPages>snapshot.val():  ",snapshot.val())
    let UsersPages=Object.keys(snapshot.val())
    console.log("getUsersPages>UsersPages:  ",UsersPages)
    resolve(UsersPages)
 reject("error on reading db returns")
}).catch((e)=>{
reject("error:  ",e)
})
    
    })
}

const getChatDocumentation=(room)=>{
            console.log("dbc>getChatDocumentation>room:  ",room)
            return new Promise ((resolve, reject) => {
        let chatArray=[]
        DB.ref(`room/${room}/content`).once('value').then((snapshot)=>{
            let chatObj=snapshot.val()
            console.log("dbc>getChatDocumentation>chatObj:  ",chatObj)
            chatArray= Object.keys(chatObj).map(k=>chatObj[k])
            console.log("dbc>getChatDocumentation>chatArray:  ",chatArray)
resolve(chatArray)
        }).catch(e=>reject("error:  "+e))
    })
}






const getUserFingerprint=()=>{
    const fpPromise = FingerprintJS.load();
return new Promise (async(resolve, reject) => {
    
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();
    
      // This is the visitor identifier:
     const visitorId = result.visitorId;
      if (visitorId) resolve(visitorId)
      reject("undefined")
    })
    
}
const sendMessage=(room,message,Fingerprint,name)=>{
    
    DB.ref(`room/${room}/content`).push({text:message,date:new Date().getTime(),sender:Fingerprint,name})
}

const getNewMessagesOn=(room,callback)=>{
    let messages=[]
        console.log("dbc>room:  ",room)
        DB.ref(`room/${room}/content`).limitToLast(1).on('child_added', (snapshot) => {
            messages.push(snapshot.val())
        console.log("dbc>messages:  ",messages)
        callback(messages)
    })

}


// const leavingReport=(room,Fingerprint,name)=>{
//     DB.ref(`room/${room}/users/${Fingerprint}/${name}/activity`).set(false)

// }
const setLeavingReport=(room,Fingerprint,name)=>{
    DB.ref(`room/${room}/users/${Fingerprint}/${name}/activity`).onDisconnect().set(false)
    // DB.ref(`room/${room}/content`).onDisconnect(()=>{sendMessage(room,name+" left the room",Fingerprint,"admin")})
    // sendMessage(room,name+" left the room",Fingerprint,"admin")

}

export {
getUserFingerprint,
userJoin,
getParticipantsOnce,
getParticipantsOn,
getUsersPages,
getChatDocumentation,
sendMessage,
getNewMessagesOn,
// leavingReport,
setLeavingReport
}