
export default  {

    apiKey: "AIzaSyDOO-gwfn1slsG4HpuhM6LVBNorBnJkeI8",
    authDomain: "chat-room-6730c.firebaseapp.com",
    projectId: "chat-room-6730c",
    storageBucket: "chat-room-6730c.appspot.com",
    messagingSenderId: "561883653273",
    appId: "1:561883653273:web:96a493a8b4ac716ca30408"
   };
