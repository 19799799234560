"use strict"

import React,{useEffect}  from 'react';
import './Message.css';
// import ReactEmoji from 'react-emoji';
import {getColor} from '../../../Auxiliary_functions'
// import Sound from 'react-sound';


const Message = ({ message, message: { text, user, userIndex }, name }) => {
  // Sound(
  // {url:"/sound.mp3",
  // playStatus:Sound.status.PLAYING,
  // playFromPosition:8500}
 
  // )
useEffect(()=>{new Audio(  "/sound2.wav").play();  },[])
   
  console.log("Message>userIndex:  ",userIndex)
  console.log("Message>message:  ",message)
  console.log("Message>user:  ",user)
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();
  
  if(user === trimmedName) {
    isSentByCurrentUser = true;
  }


  if(user === "admin") {
    var adminStyle = "adminStyle";
  }

  
  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <p className="sentText pr-10">{trimmedName}</p>
          <div className="messageBox backgroundBlue" >
          <div className="messageText colorWhite" dangerouslySetInnerHTML={{__html:SetUpLinks(text)}}></div>
          {/* <div dangerouslySetInnerHTML={{__html:`<p className="messageText colorWhite"> ${SetUpLinks(text)}</p>`}}></div> */}
          {/* <p className="messageText colorWhite">{(text)}</p> */}
          </div>
        </div>
        )
        : (
          <div className="messageContainer justifyStart">
        
            <div className={"messageBox backgroundLight"} style={{backgroundColor:getColor(userIndex)}}>
          <div className="messageText colorWhite" dangerouslySetInnerHTML={{__html:SetUpLinks(text)}}></div>
            {/* <div dangerouslySetInnerHTML={{__html:`<p className="messageText colorWhite"> ${SetUpLinks(text)}</p>`}}></div> */}
            </div>
            <p className={"sentText pl-10 " + adminStyle}>{user}</p>
          </div>
        )
  );
}

export default Message;

const SetUpLinks=(text)=>{
var newText

console.log("text.split(http).map((t)=>{t.split( )})>text:  ",text)
console.log("text.split(http).map((t)=>{t.split( )})>text.split(http):  ",text.split("http"))
// console.log("text.split(http).map((t)=>{t.split( )}):  ",
return text.split("http").map((t,i)=>{return (t.split(" ").map((innerT,innerI)=>{
  if((i!=0)&&(innerI===0)){
   var link="http"+innerT
   return(`<a href="${link}" style="color:#FFFFFF">${link}</a> `   )
  }
  else{ return innerT}
}).join(' '))}).join('')
// var textArr=
// text.split("http").map((t)=>{t.split("l")})
  // return newText
  // <script>console.log("ffffffffffffffffffffff")</script>
}