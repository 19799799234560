"use strict"

import React, {useState,useEffect} from 'react';
import usr from "../../icons/Vector.svg";
import './InfoBar.css';
// import {getParticipantsOn} from '../../firebase/DbConnection'
import {getColor} from '../../Auxiliary_functions'
import Ads from 'dugoly-shared-advertising'

const InfoBar = ({ room, name,users }) =>{ 
  console.log("InfoBar >room1:  ",room)
  // נראה לי שהמערך של המשתמשים כן מתעדכן, אבל אני לא יודע למה הוא אינו מצעדכן בתצוגה, מיפעיל ומי לא

    // const [users,setUsers]=useState([])
    // useEffect(()=>{getParticipantsOn(room,setUsers)},[room])
    console.log("InfoBar>users:  ",users)
    console.log("InfoBar >room:  ",room)

  return(
  

  <div className="infoBar" id="ib">
      <h2 class="title" >Open Chat</h2>
      <div class="yourInfo">
        <div class="wrp-yi" >  
          <div class="into-yi" >  
            <h3 className="room" >{room}</h3>
            <h3 className="name" >{name}</h3>
          </div> 
        </div> 
      </div>
      {
      users
        ? (
            <div className={"activeContainer " + (users.length >= 5 ? 'overF' : 'x')}>
            
              <h2>
                in room
                {console.log("infobar>users:  ",users)}
                <Ads 
imgPath="https://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/acaade48-8071-4458-8932-1650051dc840.gif" 
link="http://motto.co.il/" 
resize={true}
language="he"
/>
                {users.map((user,i) => (
                  <div key={name} className="activeItem">
                   <img className="activeImage" src={usr} alt="" ></img>
                    <a href={`../../users/${user.uid}/${user.username}`} style={{color:user.activity?getColor(user.index):"#A8ABAB"}} > {user.username}       </a>                   
                  </div>
                ))}
                
              </h2>
            </div>
           
        )
        : null
    }
  </div>
);

}


export default InfoBar;



