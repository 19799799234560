"use strict"

import React, {useState} from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import Message from './Message/Message';
import './Messages.css';
import Ads from 'dugoly-shared-advertising'

const Messages = ({ messages, name, users }) => {
  console.log("Messages>users:  ",users)
  const usersArray=users.map(user=>user.username)
  
  console.log("Messages>messages:  ",messages)

  const expandedMessages=messages.map((m)=>{
  console.log("m:  ",m)
  console.log("m.sender:  ",m.sender)
  let i=0
  let userIndex=-1
    while(users[i]){
      console.log("while>users[i].uid:  ",users[i].uid)
      console.log("while>m.sender:  ",m.sender)
      console.log("while>users[i].username:  ",users[i].username)
      console.log("while>name:  ",name)
if((users[i].uid===m.sender)&&(users[i].username===m.name)){
  userIndex=users[i].index
  i=-2
}
      i++
    }

    return({...m, userIndex, user:m.name})})

return ( <ScrollToBottom className="messages">
      {expandedMessages.map((message, i) => <div key={i}>{
      i%7===0&&<div heidht="90px"><br /><Ads 
      imgPath="https://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/acaade48-8071-4458-8932-1650051dc840.gif" 
      link="http://motto.co.il/" 
      resize={true}
      language="he"
      /></div>
    }<Message message={message} name={name}/></div>)}
  </ScrollToBottom>
);
}

export default Messages;