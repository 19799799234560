"use strict"

import React, { useState, useEffect } from "react";
import queryString from 'query-string';


import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';
import Back from "../Back/Back";
import {getParticipantsOn, sendMessage,getUserFingerprint, getNewMessagesOn,setLeavingReport} from '../../firebase/DbConnection'

import './Chat.css';


let fingerprin

const Chat = ({ location }) => {

  const [name, setName] = useState('');
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  
  // useEffect(()=>{getParticipantsOn(room,setUsers)},[room])
  
  useEffect(() => {
    
    const { name, room } = queryString.parse(location.search);
getUserFingerprint().then(v=>{fingerprin=v
 setLeavingReport(room,fingerprin,name)
})
    
    setRoom(room);
    setName(name)
    getNewMessagesOn(room ,(v)=> setMessages(v) )
    getParticipantsOn(room,setUsers)

  }, [room, location.search]);
  
console.log("messages:  ",messages)
  const sendMessage1 = (event) => {
    event.preventDefault();

    if(message) {
      sendMessage(room,message,fingerprin,name)
    }
  }
  if(!name)return(<div>no name</div>)

  return (
    <div className="container">
      <div className="content">
        <div className="content_info_bar">
          <InfoBar room={room} name={name} users={users} />
        </div>
        <div className="content_back">
          <Back  />
        </div>
        <div className="content_messages">
          <Messages messages={messages} name={name} users={users}/>
          <Input message={message} setMessage={setMessage} sendMessage={sendMessage1} />
        </div>
      </div> 
    </div>
  );
}


export default Chat;
